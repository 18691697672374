<!-- 剪貼簿 -->
<template lang="pug">
span
  slot(:copy="copy" :success="success" :error="error")
  //- 複製結果 (顯示成功 or 失敗)
  v-snackbar.py-2(
    :color='color'
    outlined
    v-model='snackbar'
    timeout='600'
    centered
    elevation='0'
  )
    v-icon.mr-3(:color='color') {{ hasError ? "mdi-alert" : "mdi-check-bold" }}
    span {{ copyTooltip }}
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: { type: null, default: '' }
  },
  data: () => ({
    snackbar: false,
    hasError: false,
    copyTooltip: 'Copied to Clipboard'
  }),
  computed: {
    copy () { return this.value },
    color () { return this.hasError ? 'error' : 'success' }
  },
  methods: {
    success () {
      this.hasError = false
      this.copyTooltip = this.$t('copied')
      this.snackbar = true
    },
    error (e) {
      // 不支持复制
      console.error(e)
      this.hasError = true
      this.copyTooltip = this.$t('browserNotSupport')
      this.snackbar = true
    }
  }
})
</script>

<i18n lang="json5" src="./Clipboard-i18n.json5"></i18n>
