<!-- 頁面載入動畫 -->
<template lang="pug">
div
  PageLayout(v-if="showLoading" :title="title")
    v-alert(
      v-if="errorMessage"
      type="error"
      outlined
      dense
    ) {{ errorMessage }}
    v-skeleton-loader(
      v-else
      type="article, actions"
    )
  div(v-show="!showLoading")
    slot
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  data: () => {
    return {
      showLoading: false,
      pageName: '',
      errorMessage: ''
    }
  },
  computed: {
    title () {
      return this.$t(`nav.${this.pageName}`)
    }
  },
  mounted () {
    const router = this.$router
    router.onError((error) => {
      if (error.name === 'ChunkLoadError') {
        this.errorMessage = 'Page loading error, please reload page.'
      }
    })
    router.beforeEach((to, from, next) => {
      this.errorMessage = ''
      this.showLoading = true
      this.pageName = to.name
      // 顯示加載動畫
      // console.log('beforeEach')
      next()
    })

    router.afterEach(() => {
      this.showLoading = false
      // 隱藏加載動畫
      // console.log('afterEach')
    })
  }
})
</script>
