import storage from 'store'
import keys from '@/store/storage-keys'
import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'
import vueCN from 'vuetify/lib/locale/zh-Hans'
import vueEN from 'vuetify/lib/locale/en'

Vue.use(VueI18n)

function loadLocaleMessages (): LocaleMessages {
  const messages: LocaleMessages = {}

  // 載入 Vuetify 預設的語言包
  const list = { en: vueEN, cn: vueCN }
  for (const key in list) {
    messages[key] = {
      ...(messages[key] ?? {}),
      $vuetify: list[key]
    }
  }

  // 載入資料夾內的語言檔
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  locales.keys().forEach(key => {
    const matched = key.match(/(\w+)\/([A-Za-z0-9-_]+)\./)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = {
        ...(messages[locale] ?? {}),
        ...locales(key)
      }
    }
  })

  return messages
}

export default new VueI18n({
  locale: storage.get(keys.LANG) || 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
  silentFallbackWarn: true
})
