<template lang='pug'>
v-system-bar.upperLayer(app height="50")
  v-app-bar-nav-icon(@click="$emit('click')" class="appBarCSS")
  //- v-app-bar-nav-icon(@click="$emit('click')" color='white')
  v-row.justify-end(no-gutters)
    v-col.d-flex.flex-grow-0.flex-shrink-1(cols='3')
      .d-flex.align-center.text-h6(style='color: white') {{ $store.state.app.systemTitle }}
    v-col.d-flex.justify-end.flex-grow-1.flex-shrink-0.overflow-hidden(
      cols='9'
      style='position: relative;'
    )
      MarqueeText
      .d-flex.upperLayer
        //- 額度
        .headerLabel.rounded-pill.justify-space-between.mr-2.pr-2
          .title.px-1
            span.subtitle-2.rounded-pill.pa-1 {{ $t('common.balance') }}
          .content.d-inline-flex.align-center.px-1
            //- p.ma-0.pr-1 {{ balance }}
            Cash(:value="balance")
        //- 登入帳號
        .headerLabel.rounded-pill.mr-2
          .title.px-1
            span.point.subtitle-2.rounded-pill.rounded-r-0.pa-1 {{ identity.name }}
            span.subtitle-2.rounded-pill.rounded-l-0.pa-1 {{ currency }}
          .content.d-inline-flex.flex-column.pl-1.pr-2
            p.ma-0.mb-1 {{ nickname }}
            .text-caption.flex-nowrap.d-inline-flex.align-baseline
              span ID: {{ account }}
              //- 複製
              Clipboard.ml-2(v-model="account" v-slot="{copy, success, error}")
                v-btn.clipboardBtnCSS(
                  icon
                  x-small
                  color='#adc392'
                  v-clipboard:copy="copy"
                  v-clipboard:success="success"
                  v-clipboard:error="error"
                )
                  v-icon mdi-note-multiple-outline
        //- 語系
        Language.mr-2
        //- 點數兌換異動通知
        CreditExchangeNotification.mr-2
        //- 收信夾
        CreditMessageNotification.mr-2
        //- v-btn.mx-2.upperLayer(depressed fab small @click="$refs.inbox.show()")
        //-   v-icon.appbarIcon--text mdi-email
        //- 登出
        Logout
  //- Inbox(ref="inbox")
</template>

<script>
import LanguageVue from './Language.vue'
import LogoutVue from './Logout.vue'
import MarqueeText from './Marquee.vue'
// import Inbox from '@/views/Inbox/index.vue'
import { mapState } from 'vuex'
import Clipboard from '@/components/Clipboard.vue'
import CreditExchangeNotification from './credit-exchange-notification.vue'
import CreditMessageNotification from './credit-message-notification.vue'

export default {
  components: {
    Logout: LogoutVue,
    Language: LanguageVue,
    MarqueeText,
    // Inbox,
    Clipboard,
    CreditExchangeNotification,
    CreditMessageNotification
  },
  computed: {
    ...mapState('user', ['account', 'nickname', 'currency', 'identity', 'balance'])
  },
  methods: {
    // 取得錢包資訊
    getWallet () {
      this.$store.dispatch('user/Wallet')
    }
  },
  mounted () {
    this.getWallet()
  }
}
</script>

<style lang='sass' scoped>
@import '@/assets/sass/variable'

.headerLabel
  height: 40px
  display: inline-flex
  background: #FFF
  &:first-child
    position: relative
    &::before
      content: ''
      position: absolute
      top: 0
      left: -10px
      width: 10px
      height: 100%
      background: $appbar-bg-color
  .title
    display: flex
    align-items: center
    color: #FFF
    span
      word-break: keep-all
      background: #666
      &.point
        background: #64A417
  .content
    color: #000
    p, span
      word-break: keep-all
    .text-caption
      margin-top: -7px
      white-space: nowrap
.upperLayer
  z-index: 2
  background: #543324

.theme--light.v-system-bar .appBarCSS .v-icon
  color: rgba(235, 235, 235)

.theme--light.v-system-bar .clipboardBtnCSS .v-icon
  margin-top: -6px
  color: #adc392
</style>
